import { Component, Vue, Prop } from 'vue-property-decorator'
import http from '@/services/http'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
//import TypeResidency from '@/Models/TypeResidency'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class Card extends Vue {
  @Prop({ required: true })
  item!: any
  loading = false
  @Prop({ required: true })
  way!: string
  @Prop({ required: true })
  editField!: string
  @Prop({ required: true })
  module!: string

  close(type: boolean) {
    this.$emit('close_edit', { item: this.item, close_success: type })
  }

  async updateObj() {
    this.item[this.editField] = this.item.value
    try {
      await http.put(
        `api/${this.module}/${this.way}/${this.item.id}/`,
        this.item,
      )
      this.$buefy.toast.open({
        message: 'Actualizado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close(true)
      }, 1000)
    } catch (error: any) {
      //console.error(error)
      this.loading = false

      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
  }
}
/* this.$buefy.toast.open({
  message: error.response.data,
  type: 'is-danger',
}) */
