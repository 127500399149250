var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"type":{
        'is-danger': errors[0],
        'is-success': valid,
      }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.value),expression:"item.value"}],staticClass:"input is-normal",attrs:{"type":"text","placeholder":"Normal input","maxlength":"50"},domProps:{"value":(_vm.item.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.item, "value", $event.target.value)}}})])]}}],null,true)}),_c('div',{staticClass:"is-flex is-justify-content-space-between pt-3"},[_c('div',[_c('p',{staticClass:"has-text-left is-size-7"},[_vm._v("Editando...")])]),_c('div',{staticClass:"is-flex is-justify-content-flex-end"},[_c('div',{staticClass:"pr-2"},[_c('i',{staticClass:"mdi mdi-check-bold",on:{"click":function($event){return handleSubmit(_vm.updateObj)}}})]),_c('div',[_c('i',{staticClass:"mdi mdi-close-thick",on:{"click":_vm.close}})])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }